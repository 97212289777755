import {getDictDataListByCodeApi} from "@/api/sys/dict";

export default{
    namespaced:true,
    state:{
        deviceType:[], //设备类型列表
    },

    mutations:{
        setDeviceType(state, deviceType) {
            state.deviceType = deviceType;
        },
    },

    actions:{
        async asyncGetDeviceType({ commit },payload){
            let params = {
                dictType:'validate_device_type'
            };
            let res = await getDictDataListByCodeApi(params);
            if(!res)return;
            commit("setDeviceType", res.rows)
        },
    }
}
